import { JZ } from "@buzzbike/ui/src/DesignSystem";
import {
  chakra,
  HStack,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCities } from "hooks/useCities";
import { useSegment } from "hooks/useSegment";
import { useRouter } from "next/router";

import GeographicWaitlistModal from "components/modal/GeographicWaitlistModal";
import SignupLayout from "components/newUI/SignupLayout";
import StyledSecondaryButton from "components/StyledSecondaryButton";
import {
  getCheckoutPageHandler,
  updateCheckoutCookiesFromClient,
} from "utils/checkout";
import {
  SignupFunnelStep,
  trackSeletedCity,
  useTrackSignupFunnelStepStarted,
} from "utils/tracking";

export const getServerSideProps = getCheckoutPageHandler("city");

export default function Page() {
  const router = useRouter();
  const segment = useSegment();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: citiesData } = useCities();

  useTrackSignupFunnelStepStarted(SignupFunnelStep.City);

  const handleOnContinue = async (cityId: number) => {
    const city = citiesData?.cities.find((i) => i.id === cityId);
    trackSeletedCity(segment, city?.name || "London");
    await updateCheckoutCookiesFromClient("checkout", {
      cityId,
      cityName: city?.name,
      validPostcode: {
        postalAreaList: city?.postalAreaList || undefined,
        postalDistrictList: city?.postalDistrictList || undefined,
      },
    });
    await router.push({
      pathname: "60-plan",
      query: {
        ...router.query,
      },
    });
  };

  return (
    <SignupLayout step="step0">
      <Stack
        maxW={"xl"}
        spacing={4}
        margin="auto"
        justifyContent={"center"}
        minH="60vh"
        textAlign="center"
      >
        <Text {...JZ["Web/Title/40 Semi"]} fontSize={32} color={JZ.BuzzBlack}>
          Where do you live?
        </Text>
        <Stack direction={["column", "row"]}>
          {citiesData?.cities
            .map(({ id, name }) => (
              <StyledSecondaryButton
                key={`${id}_${name}`}
                flex={1}
                minH={14}
                onClick={() => handleOnContinue(id)}
              >
                <HStack alignItems="center">
                  <Text {...JZ["Web/Body/20 Med"]}>{name}</Text>
                  <chakra.span
                    {...JZ["Body/18 Reg"]}
                    fontSize={16}
                    fontWeight="light"
                  >
                    (Greater)
                  </chakra.span>
                </HStack>
              </StyledSecondaryButton>
            ))
            .reverse()}
        </Stack>
        <Link {...JZ["Web/Caption/16 Med"]} pt={2} onClick={onOpen}>
          Elsewhere? Get notified about our expansion!
        </Link>
      </Stack>
      <GeographicWaitlistModal
        cities={citiesData?.cities}
        isOpen={isOpen}
        onClose={onClose}
        onJoinNow={(cityId) => handleOnContinue(cityId)}
      />
    </SignupLayout>
  );
}
