import { JZ } from "@buzzbike/ui/src/DesignSystem";
import { Button, ButtonProps } from "@chakra-ui/react";

function StyledSecondaryButton(props: ButtonProps) {
  return (
    <Button
      {...JZ["Web/Caption/16 Med"]}
      textTransform="none"
      rounded={16}
      h={14}
      background={JZ["Pink/1"]}
      color={JZ.Pink}
      _hover={{ background: "#FFE4EB", color: JZ["Pink/3"] }}
      _active={{
        background: JZ["Pink/1.5"],
        color: "#CA1244",
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}
export default StyledSecondaryButton;
